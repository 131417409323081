import localeAntd from 'antd/es/locale/uk_UA'

const messages = {
  'auth.form.info1': 'Увійдіть у свій акаунт',
  'auth.form.info2': 'Ваш логін - це номер NIP, номер PESEL або номер паспорта.',
  'auth.form.info3': 'Пароль надіслано в SMS.',

  'auth.login': 'Увійти',
  'auth.password': 'Пароль',
  'auth.form.login.placeholder': 'номер PESEL або номер NIP або номер паспорта',

  'auth.logout': 'Вийти',

  'menu.declarations': 'Ваші декларації',
  'declarations.title': 'Ваші декларації',

  'declarations.table.employer': 'Роботодавець',
  'declarations.table.documentNumber': 'Номер документа',
  'declarations.table.documentType': 'Тип документа',
  'declarations.table.download': 'Завантажити',
}

export default {
  locale: 'uk-UA',
  localeAntd,
  messages,
}
