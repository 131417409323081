import axios from 'axios'
import store from 'store'
import { notification } from 'antd'

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // timeout: 1000,
  // headers: { 'X-Custom-Header': 'foobar' }
})

apiClient.interceptors.request.use(request => {
  const accessToken = store.get('auth.accessToken')
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
  }
  return request
})

apiClient.interceptors.response.use(undefined, error => {
  // Errors handling
  const { response } = error
  if (response.status === 401) {
    const { config } = response

    if (config.url === '/auth/login') {
      notification.warn({
        message: 'Wprowadzono błędne dane',
      })
    } else {
      notification.info({
        message: 'Zaloguj się aby kontynuować',
      })
    }
  } else {
    console.error(response)
  }
})

export default apiClient
