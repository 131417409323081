import localeAntd from 'antd/es/locale/ru_RU'

const messages = {
  'auth.form.info1': 'Войти в систему',
  'auth.form.info2': 'Ваш логин - это номер NIP, номер PESEL или номер паспорта.',
  'auth.form.info3': 'Пароль был отправлен в SMS.',

  'auth.login': 'Вход',
  'auth.password': 'Пароль',

  'auth.form.login.placeholder': 'Номер PESEL или номер NIP или номер паспорта',
  'auth.logout': 'Выйти',

  'menu.declarations': 'Ваши декларации',
  'declarations.title': 'Ваши декларации',

  'declarations.table.employer': 'Работодатель',
  'declarations.table.documentNumber': 'Номер документа',
  'declarations.table.documentType': 'Тип документа',
  'declarations.table.download': 'Скачать',
}

export default {
  locale: 'ru-RU',
  localeAntd,
  messages,
}
