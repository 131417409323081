import localeAntd from 'antd/es/locale/en_US'

const messages = {
  'auth.form.info1': 'Log in to your account',
  'auth.form.info2': 'Your login is your NIP, PESEL or passport number.',
  'auth.form.info3': 'The password has been sent via SMS.',

  'auth.login': 'Log in',
  'auth.password': 'Password',

  'auth.form.login.placeholder': 'PESEL number or NIP number or passport number',

  'declarations.title': 'Your tax forms',
  'declarations.table.employer': 'Employer',
  'declarations.table.documentNumber': 'Document number',
  'declarations.table.documentType': 'Document type',
  'declarations.table.download': 'Download',
}

export default {
  locale: 'en-US',
  localeAntd,
  messages,
}
