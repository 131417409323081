import apiClient from 'services/axios'
import store from 'store'

export async function login(account, password) {
  const postData = new FormData()
  postData.append('account', account)
  postData.append('password', password)

  return apiClient
    .post('/auth/login', postData)
    .then(response => {
      if (response && response.data) {
        const { access_token: accessToken, refresh_token: refreshToken, role } = response.data

        if (accessToken && refreshToken) {
          store.set('auth.accessToken', accessToken)
          store.set('auth.refreshToken', refreshToken)
          store.set('auth.role', role)
        }
        return response.data
      }
      console.log(' auth bedzie return false')
      return false
    })
    .catch(err => console.log(err))
}

export async function currentAccount() {
  const role = store.get('auth.role')
  let url = ''
  if (role === 'employee') {
    url = '/employee/account'
  } else if (role === 'client') {
    url = '/client/account'
  } else {
    console.error(`Wrong role value: ${role}`)
    return false
  }

  return apiClient
    .get(url)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function logout() {
  return apiClient
    .get('/auth/logout')
    .then(() => {
      store.remove('auth.accessToken')
      store.remove('auth.refreshToken')
      store.remove('auth.role')
      return true
    })
    .catch(err => console.log(err))
}
