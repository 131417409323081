export default async function getMenuData() {
  return [
    {
      title: 'Twoje deklaracje',
      key: 'employee-documents',
      icon: 'fe fe-file-text',
      url: '/employee/documents',
      roles: ['employee'],
    },
    {
      title: 'Wyloguj',
      key: 'employee-logout',
      icon: 'fe fe-log-out',
      url: '/auth/logout',
      roles: ['employee'],
    },
  ]
}
