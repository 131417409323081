import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'
import * as auth from 'services/auth'
import actions from './actions'

export function* LOGIN({ payload }) {
  const { account, password } = payload

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(auth.login, account, password)

  if (response) {
    const { name, role, issued_at: issuedAt, expiration_time: expirationTime } = response
    yield put({
      type: 'user/SET_STATE',
      payload: {
        name,
        role,
        issuedAt,
        expirationTime,
        authorized: true,
      },
    })

    /* Load user data. */
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })

    yield history.push('/')
    notification.success({
      message: 'Zalogowano',
    })
  }
  if (!response) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(auth.currentAccount)
  if (response) {
    const { name, role } = response
    yield put({
      type: 'user/SET_STATE',
      payload: {
        name,
        role,
        authorized: true,
      },
    })
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOGOUT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  yield call(auth.logout)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      name: '',
      role: '',
      authorized: false,
      loading: false,
    },
  })

  yield history.push('/')
  notification.success({
    message: 'Wylogowano',
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
