import localeAntd from 'antd/es/locale/pl_PL'

const messages = {
  'auth.form.info1': 'Zaloguj się do swojego konta',
  'auth.form.info2': 'Twoim loginem jest numer NIP, PESEL lub numer paszportu.',
  'auth.form.info3': 'Hasło zostało wysłane w SMS.',

  'auth.login': 'Zaloguj się',
  'auth.password': 'Hasło',

  'auth.form.login.placeholder': 'Numer PESEL lub numer NIP',

  'declarations.title': 'Twoje deklaracje',
  'declarations.table.employer': 'Pracodawca',
  'declarations.table.documentNumber': 'Numer dokumentu',
  'declarations.table.documentType': 'Typ dokumentu',
  'declarations.table.download': 'Pobierz',
}

export default {
  locale: 'pl-PL',
  localeAntd,
  messages,
}
